class BubblePhysics {
  constructor (items, boxWidth, boxHeight) {
    this.rigidBodies = this.randomPositions(items, boxWidth, boxHeight)
    this.on = false
    this.intervalId = null
    this.lastBoxWidth = boxWidth
    this.lastBoxHeight = boxHeight
  }

  randomPositions (items, boxWidth, boxHeight) {
    return items.map(item => ({
      ...item,
      position: {
        x: Math.random() * (boxWidth - this.getItemSize(item, boxWidth, boxHeight)),
        y: (1 - item.points / 14) * (boxHeight - this.getItemSize(item, boxWidth, boxHeight))
      },
      velocity: {
        x: 0, y: 0
      }
    }))
  }

  weightedRandom(weight) {
    return Math.pow(Math.random(), Math.pow(2, -weight))
  }

  start() {
    this.on = true
    // this.update()
    this.intervalId = window.setInterval(this.update.bind(this), 50)
  }

  stop() {
    this.on = false
    window.clearInterval(this.intervalId)
  }

  distInfo (pos1, pos2) {
    const dx = pos2.x - pos1.x
    const dy = pos2.y - pos1.y
    let dist = Math.sqrt(dx * dx + dy * dy)
    if (dist == 0) {
      console.log('warning: dist 0 encountered')
    }
    dist = Math.max(1e-5, dist)
    return { dist, dx, dy }
  }

  applyForce(item, dx, dy) {
    const rigidBody = this.rigidBodies.find(myItem => myItem.veryShort == item.veryShort)
    rigidBody.velocity.x += 100 / (item.points + 2) * dx
    rigidBody.velocity.y += 100 / (item.points + 2) * dy
  }

  update() {
    for (let i = 0; i < this.rigidBodies.length; i++) {
      const item1 = this.rigidBodies[i]
      const item1Size = this.getItemSize(item1, this.lastBoxWidth, this.lastBoxHeight)
      for (let j = i + 1; j < this.rigidBodies.length; j++) {
        const item2 = this.rigidBodies[j]
        const item2Size = this.getItemSize(item2, this.lastBoxWidth, this.lastBoxHeight)
        // calculate distance
        const distInfo = this.distInfo(item1.position, item2.position)
        // spring force
        let force = 20 / distInfo.dist
        if (distInfo.dist > 0.5 * (item1Size + item2Size)) {
          force = 0
        }
        // apply force
        item1.velocity.x -= force * distInfo.dx
        item1.velocity.y -= force * distInfo.dy
        item2.velocity.x += force * distInfo.dx
        item2.velocity.y += force * distInfo.dy
      }
      // buoyancy
      const buoyancyForce = 4 * Math.sqrt(item1.points) - 5
      item1.velocity.y -= buoyancyForce
      // random force
      item1.velocity.x += (Math.random() - 0.5) * 5
      item1.velocity.y += (Math.random() - 0.5) * 5
    }
    for (let i = 0; i < this.rigidBodies.length; i++) {
      const item = this.rigidBodies[i]
      const itemRadius = 0.5 * this.getItemSize(item, this.lastBoxWidth, this.lastBoxHeight)

      // apply drag
      item.velocity.x *= 0.5
      item.velocity.y *= 0.5
      // apply velocity with movement threshold
      if (Math.abs(item.velocity.x) > 1) {
        item.position.x += 0.5 * item.velocity.x
      }
      if (Math.abs(item.velocity.y) > 1) {
        item.position.y += 0.5 * item.velocity.y
      }
      // apply bounds
      if (item.position.x > this.lastBoxWidth - itemRadius || item.position.x < itemRadius) {
        item.velocity.x *= -0.5
      }
      if (item.position.y > this.lastBoxHeight - itemRadius || item.position.y < itemRadius) {
        item.velocity.y *= -0.5
      }
      item.position.x = Math.max(Math.min(item.position.x, this.lastBoxWidth - itemRadius), itemRadius)
      item.position.y = Math.max(Math.min(item.position.y, this.lastBoxHeight - itemRadius), itemRadius)
    }
    // this.on && window.requestAnimationFrame(this.update.bind(this))
  }

  getItemPosition(item, boxWidth, boxHeight) {
    const itemRadius = 0.5 * this.getItemSize(item, this.lastBoxWidth, this.lastBoxHeight)
    const rigidBody = this.rigidBodies.find(myItem => myItem.veryShort == item.veryShort)
    return {
      x: rigidBody.position.x - itemRadius,
      y: rigidBody.position.y - itemRadius
    }
  }

  getItemSize (item, boxWidth, boxHeight) {
    this.lastBoxWidth = boxWidth
    this.lastBoxHeight = boxHeight
    return (item.points + 3) / 30 * Math.min(boxWidth, boxHeight)
  }

  setPoints(item, points) {
    this.rigidBodies.find(myItem => myItem.veryShort == item.veryShort).points = points
  }
}

export default BubblePhysics
