<template>
  <div>
    <template v-if="pointsValid || true">
      <p>
        Geef vervolgens aan wat jouw relatie tot educatie is:
      </p>
      <p class="">
        Ik ben...
      </p>
      <p class="center">
        <square-toggle
          v-for="(name, index) in levelOneSettings"
          :key="index"
          :name="name"
          @change="on => toggleEducationRelation(name, on)"
        />
      </p>
      <p class="center">
        <square-toggle
          v-for="(name, index) in levelTwoSettings"
          :key="index"
          :name="name"
          :invert="true"
          @change="on => toggleEducationType(name, on)"
        />
      </p>
    </template>
    <template v-if="sent">
      <p>
        Bedankt voor het doorgeven van jouw keuzes!
      </p>
      <p>
        Voor updates over het project kun je deze pagina in de gaten houden of een mailtje sturen naar <b><a href="mailto:info@ethischeassistent.nl">info@ethischeassistent.nl</a></b>.
      </p>
    </template>
    <template v-if="!sent && allValid">
      <p class="">
        Geef je keuzes door en draag bij aan deze collectieve afweging!
      </p>
      <div class="center">
        <normal-button name="verzend" color="yellow" size="l" @click="send" />
      </div>
    </template>
  </div>
</template>

<script>
import NormalButton from './NormalButton.vue'
import SquareToggle from './SquareToggle.vue'

export default {
  components: {
    SquareToggle,
    NormalButton
  },
  data() {
    return {
      levelOneSettings: [
        'een leerling',
        'de ouder van een leerling',
        'werkzaam in het onderwijs',
        'anders'
      ],
      levelTwoSettings: [
        'op de basisschool',
        'in het voortgezet onderwijs',
        'in het hoger onderwijs',
        'anders'
      ]
    }
  },
  computed: {
    pointsValid() { return this.$store.getters.getPointsLeft < this.$store.getters.getTotalPoints },
    educationSettingsValid() { return this.$store.getters.getEducationSettingsValid },
    allValid() { return this.pointsValid && this.educationSettingsValid },
    sent() { return this.$store.getters.getSent }
  },
  methods: {
    toggleEducationRelation (relation, on) {
      this.$store.commit('toggleEducationRelation', { relation, on })
    },
    toggleEducationType (type, on) {
      this.$store.commit('toggleEducationType', { type, on })
    },
    send() {
      this.$store.dispatch('send')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.send-button {
  width: 11rem;
  height: 4rem;
  line-height: 4rem;
  padding: 0rem;
  margin: 1.5rem auto 0 auto;
  text-align: center;
  border-radius: 1rem;
  cursor: pointer;
  // color: $pink;
  font-weight: 700;
  font-size: 0.8rem;
  background-color: $yellow;
  box-shadow: -2px 8px 15px #fbdd73b0;
}
</style>
