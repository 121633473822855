const items = [{
  short: 'Gegevens over het leergedrag worden niet gedeeld met derden',
  veryShort: 'delen met derden',
  long: 'Gegevens over het leergedrag blijven enkel in het systeem. Een leerling mag niet ineens meer reclames voor lego technic tegenkomen omdat ze de rekenopdrachten vlotter maakt dan de spelling. ',
  type: 'threat',
  points: 0
},
{
  short: 'Na afloop mogen er geen gegevens gekoppeld aan een leerling overblijven',
  veryShort: 'data behouden',
  long: 'Wanneer een leerling niet meer gebruik maakt van het systeem blijven er geen gegevens over gekoppeld aan of herleidbaar tot diens identiteit.',
  type: 'threat',
  points: 0
},
{
  short: 'Het systeem mag niet leiden tot een vicieuze cirkel',
  veryShort: 'vicieuze cirkel',
  long: 'Er moet goed worden opgelet op ‘positieve terugkoppeling’: afwijkingen die door het systeem worden versterkt. Wanneer een leerling bijvoorbeeld achterloopt, moet het systeem ertoe leiden dat deze achterstand wordt ingehaald, niet versterkt wordt.',
  type: 'threat',
  points: 0
},
{
  short: 'Het systeem mag niet leiden tot oneerlijke vooroordelen',
  veryShort: 'vooroor<wbr>delen',
  long: 'AI modellen zijn berucht voor het ontwikkelen van vooroordelen (bias) op basis van bijvoorbeeld gender of herkomst. Dit mag niet gebeuren in een leerlingvolgsysteem.',
  type: 'threat',
  points: 0
},
{
  short: 'Een leerling mag niet het gevoel hebben constant gemonitord te worden',
  veryShort: 'monitoring',
  long: 'Leren is oefenen: er is ruimte nodig om in vrijheid fouten kunnen te maken zonder dat deze worden bijgehouden. Om nog maar te zwijgen over camera’s en aandacht- of emotie-herkenningstechnologie.',
  type: 'threat',
  points: 0
},
{
  short: 'Er moet ruimte zijn voor uitzonderingen',
  veryShort: 'uitzonder<wbr>ingen',
  long: 'Elke leerling is anders. Digitalisering leidt inherent tot categorisering, een oneindig gevarieerde wereld wordt ingedeeld in een eindig aantal discrete vakjes. Maar misschien is het juiste antwoord wel niet A of B. Er moet altijd ruimte zijn voor uitzonderingen. Dit gaat ook op voor processen: misschien werkt de een liever met drijvende bubbels en heeft een ander liever een geordende lijst.',
  type: 'threat',
  points: 0
},
// opportunities
{
  short: 'Werkdruk van de docent verlichten',
  veryShort: 'werkdruk verlichten',
  long: 'De tijd van de docent kan efficiënter worden benut als leerlingen zelfstandig met het systeem aan de slag kunnen.',
  type: 'opportunity',
  points: 0
},
{
  short: 'Het leerproces versoepelen',
  veryShort: 'leerproces versoepelen',
  long: 'Een leerling krijgt alleen de stof die het op dat moment nodig heeft. Dus geen bekende stof of te makkelijke opdrachten, waardoor het de aandacht verliest, maar ook geen te moeilijke opdrachten die weer leiden tot frustratie en onzekerheid.',
  type: 'opportunity',
  points: 0
},
{
  short: 'Het leerproces leuk maken',
  veryShort: 'leuker leren',
  long: 'Digitalisering biedt natuurlijk mogelijkheden: het leerproces kan een afwisseling van spelletjes, filmpjes en afbeeldingen worden. De aantrekkingskracht van games en social media kunnen we ook voor een educatief doel aanwenden.',
  type: 'opportunity',
  points: 0
},
{
  short: 'Inzicht verschaffen',
  veryShort: 'inzicht',
  long: 'Een leerlingvolgsysteem biedt een gedetailleerd inzicht in de voortgang van de leerlingen.',
  type: 'opportunity',
  points: 0
}
]

export default items
