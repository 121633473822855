<template>
  <div id="app">
    <b-container fluid class="main-container pad-1">
      <b-row>
        <b-col id="col-1" md="12" lg="12" xl="4">
          <intro />
        </b-col>
        <b-col id="col-2" md="12" lg="6" xl="4">
          <controls />
          <list-holder />
        </b-col>
        <b-col id="col-3" md="12" lg="6" xl="4">
          <template v-if="selectedItem">
            <explanation />
          </template>
          <hoe-werkt-het />
          <settings />
        </b-col>
      </b-row>
    </b-container>
    <b-button id="menu-toggle" v-b-toggle.sidebar-1>
      <b-icon-list />
    </b-button>
    <menu-sidebar />
  </div>
</template>

<script>
import MenuSidebar from './components/MenuSidebar'
import { BIconList } from 'bootstrap-vue'
import Intro from './articles/Intro'
import HoeWerktHet from './articles/HoeWerktHet'
import ListHolder from './components/ListHolder'
import Settings from './components/Settings'
import Explanation from './components/Explanation.vue'
import Controls from './components/Controls.vue'

export default {
  name: 'App',
  components: {
    MenuSidebar,
    BIconList,
    Intro,
    HoeWerktHet,
    ListHolder,
    Settings,
    Explanation,
    Controls
  },
  computed: {
    selectedItem() {
      return this.$store.getters.getSelectedItem
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/index.scss';

#menu-toggle {
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  color: $primary;
  background: none;
  border: none;
  box-shadow: none;
  height: 20px;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .pad-1, .container.pad-1, .container-fluid.pad-1, .pad-1.container-sm, .pad-1.container-md, .pad-1.container-lg, .pad-1.container-xl {
    padding: 2.5rem 1.5rem;
  }
}

#col-1, #col-2 {
  margin-bottom: 1.5rem;
}
</style>
