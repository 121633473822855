<template>
  <div ref="bubbleBox" class="bubbles">
    <div
      v-for="(item, idx) in items"
      :key="idx"
      :class="['bubble', item.type, item == selectedItem ? 'selected' : '']"
      :style="bubbleStyle(item)"
      @mousedown="grab(item)"
      @mousemove="move(item, $event)"
      @mouseout="release()"
      @mouseup="release()"
      @click="select(item)"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="bubble-name" :style="bubbleNameStyle(item)" v-html="`${item.veryShort}<br>${item.points}`" />
    </div>
  </div>
</template>

<script>
import BubblePhysics from '@/utils/BubblePhysics'

export default {
  components: {
  },
  data() {
    return {
      physics: null,
      items: this.$store.getters.getItems,
      boxWidth: 0,
      boxHeight: 0,
      grabbedItem: null
    }
  },
  computed: {
    selectedItem() {
      return this.$store.getters.getSelectedItem
    }
  },
  beforeMount() {
    this.$store.commit('setCurrentBubbles', this)
  },
  mounted () {
    this.getBoxSize()
    window.onresize = this.getBoxSize.bind(this)
    this.physics = new BubblePhysics(this.items, this.boxWidth, this.boxHeight)
    this.physics.start()
  },
  methods: {
    getBoxSize() {
      this.boxWidth = this.$refs.bubbleBox.clientWidth
      this.boxHeight = this.$refs.bubbleBox.clientHeight
    },
    select(item) {
      this.$store.commit('selectItem', item)
    },
    plus(item) {
      if (item.points < 15 && this.$store.getters.getPointsLeft > 0) {
        this.$set(item, 'points', item.points + 1)
        this.physics.setPoints(item, item.points)
      }
    },
    min(item) {
      if (item.points > 0) {
        this.$set(item, 'points', item.points - 1)
        this.physics.setPoints(item, item.points)
      }
    },
    grab(item) {
      this.grabbedItem = item
    },
    move(item, event) {
      if (this.grabbedItem == item) {
        this.physics.applyForce(item, event.movementX, event.movementY)
      }
    },
    release() {
      this.grabbedItem = null
    },
    bubbleStyle (item) {
      if (!this.physics) {
        return {}
      }
      const size = this.physics.getItemSize(item, this.boxWidth, this.boxHeight)
      const sizePx = `${size}px`
      const itemPosition = this.physics.getItemPosition(item, this.boxWidth, this.boxHeight)
      const x = itemPosition ? itemPosition.x : 0
      const y = itemPosition ? itemPosition.y : 0
      return {
        width: sizePx,
        height: sizePx,
        borderRadius: sizePx,
        left: `${x}px`,
        top: `${y}px`,
        lineHeight: sizePx
      }
    },
    bubbleNameStyle (item) {
      if (!this.physics) {
        return {}
      }
      const size = this.physics.getItemSize(item, this.boxWidth, this.boxHeight)
      return {
        top: `calc(${0.4 * size - 6}px)`,
        fontSize: `${3 + size * 0.1}px`
      }
    },
    bubblePointsHolderStyle (item) {
      if (!this.physics) {
        return {}
      }
      const size = this.physics.getItemSize(item, this.boxWidth, this.boxHeight)
      return {
        bottom: `${0.2 * size}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.bubbles {
  position: relative;
  width: 100%;
  height: 80vh;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.bubble {
  position: absolute;
  text-align: center;
  font-size: 1.5vh;
  box-sizing: content-box;
  transition: left 0.2s linear, top 0.2s linear;
}
.bubble.opportunity {
    color: $color-blue-dark;
    background-color: $white;
    box-shadow: -2px 8px 15px #d0d0d081;
}
.bubble.threat {
    color: $pink;
    background-color: $color-blue-dark;
    box-shadow: -2px 8px 15px #0018573d;
}
.bubble.threat.selected {
    border: 0.2rem solid $white;
    transform: translate(-4px, -4px);
    box-shadow: -2px 8px 15px -5px $white;
}
.bubble.opportunity.selected {
    border: 0.2rem solid $color-blue-dark;
    transform: translate(-4px, -4px);
    box-shadow: -2px 8px 15px -5px $color-blue-dark;
}

.bubble-name {
  display:inline-block;
  vertical-align: middle;
  line-height: normal;
}
</style>
