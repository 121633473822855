<template>
  <draggable
    v-model="sortedItems"
    group="people"
    class="list"
    @start="drag=true"
    @end="drag=false"
    @change="args => args.moved && select(args.moved.element)"
  >
    <div
      v-for="(item, idx) in sortedItems"
      :key="idx"
      :class="['list-item', item.type, item == selectedItem ? 'selected' : '']"
      @click="select(item)"
    >
      <b>{{ item.type == 'threat' ? '-' : '+' }}&emsp;</b>
      {{ item.short }}
      <div class="points my-points">
        {{ item.points }}
      </div>
      <!-- <div class="points total-points">
        {{ Math.floor(Math.random() * 500) }}
      </div> -->
    </div>
  </draggable>
</template>

<script>
// import { BIconSun, BIconTriangleFill } from 'bootstrap-vue'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
    // BIconSun,
    // BIconTriangleFill
  },
  computed: {
    sortedItems: {
      get() {
        return this.$store.getters.getSortedItems
      },
      set(items) {
        this.$store.commit('setSortedItems', items)
      }
    },
    selectedItem() {
      return this.$store.getters.getSelectedItem
    }
  },
  methods: {
    select(item) {
      console.log(item)
      this.$store.commit('selectItem', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.list {
    padding: 1.5rem;
}
.list-item {
    position: relative;
    border-radius: 0.5rem;
    padding: 0.5rem 3.5rem 0.5rem 0.5rem;
    font-size: 0.7rem;
    cursor: pointer;
    margin-bottom: 1rem;
    box-sizing: border-box;
}
.list-item.opportunity {
    background-color: $white;
    box-shadow: -2px 8px 15px #d0d0d081;
}
.list-item.threat {
    color: $pink;
    background-color: $color-blue-dark;
    box-shadow: -2px 8px 15px #0018573d;
}
.list-item.selected {
    padding: 0.3rem 3.3rem 0.3rem 0.3rem;
}
.list-item.threat.selected {
    border: 0.2rem solid $white;
    box-shadow: -2px 8px 15px -5px $white;
}
.list-item.opportunity.selected {
    border: 0.2rem solid $color-blue-dark;
    box-shadow: -2px 8px 15px -5px $color-blue-dark;
}
.points {
  position: absolute;
  top: 0.35rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  padding: 0.2rem;
}
.list-item.selected .points {
  top: 0.15rem;
}
.points.my-points {
  text-align: center;
  right: 0.35rem;
  background-color: $pink;
  color: $color-blue-dark;
  font-weight: 700;
}
.list-item.selected .points.my-points {
  right: 0.15rem;
}
.points.total-points {
  text-align: right;
  right: 2rem;
  opacity: 0.5;
}
.list-item.selected .points.total-points {
  right: 1.8rem;
}
</style>
