<template>
  <div class="list-holder">
    <list v-if="listType == 'list'" />
    <bubbles v-if="listType == 'bubbles'" />
  </div>
</template>

<script>
import Bubbles from './Bubbles.vue'
import List from './List.vue'

export default {
  components: { List, Bubbles },
  computed: {
    listType() { return this.$store.getters.getListType }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/variables.scss';

.list-holder {
    background-color: $yellow;
    border-radius: 1rem;
    width: 100%;
    // height: 80vh;
    box-shadow: -2px 8px 15px #fbdd73b0;
    margin-bottom: 1rem;
    // box-shadow: -15px 15px 20px -10px inset #e5c8628c;
}
</style>
