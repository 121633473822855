import Vue from 'vue'
import Vuex from 'vuex'
import startItems from './items.js'

function sortPure(arr, sortFun) {
  const arrCopy = [...arr]
  arrCopy.sort(sortFun)
  return arrCopy
}

const totalPoints = 55
const postUrl = './post.php'
// const postUrl = 'http://localhost:8888/ethischeassistent/test.php'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    items: [...startItems],
    selectedItem: null,
    currentBubbles: null,
    listType: 'bubbles',
    educationRelations: [],
    educationTypes: [],
    sent: false
  },
  getters: {
    getItems: state => state.items,
    getSortedItems: state => sortPure(state.items, (a, b) => b.points - a.points),
    getSelectedItem: state => state.selectedItem,
    getCurrentBubbles: state => state.currentBubbles,
    getPointsLeft: state => totalPoints - state.items.reduce((total, item) => total + item.points, 0),
    getListType: state => state.listType,
    getEducationRelations: state => state.educationRelations,
    getEducationTypes: state => state.educationTypes,
    getEducationSettingsValid: state => state.educationRelations.length > 0 && state.educationTypes.length > 0,
    getTotalPoints: state => totalPoints,
    getSent: state => state.sent
  },
  mutations: {
    setSortedItems(state, items) {
      state.items = items.map((item, idx) => {
        item.points = items.length - idx
        return item
      })
    },
    selectItem(state, item) {
      state.selectedItem = item
    },
    setCurrentBubbles(state, bubbles) {
      state.currentBubbles = bubbles
    },
    setListType(state, type) {
      state.listType = type
    },
    toggleEducationRelation(state, { relation, on }) {
      // remove from ist if exists
      state.educationRelations = state.educationRelations.filter(item => item != relation)
      // add
      if (on) {
        state.educationRelations.push(relation)
      }
    },
    toggleEducationType(state, { type, on }) {
      // remove from ist if exists
      state.educationTypes = state.educationTypes.filter(item => item != type)
      // add
      if (on) {
        state.educationTypes.push(type)
      }
    },
    setSent(state, value) {
      state.sent = value
    }
  },
  actions: {
    send({ state, commit }) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          factors: state.items,
          educationRelations: state.educationRelations,
          educationTypes: state.educationTypes
        })
      }
      fetch(postUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          if (data.success) {
            console.log(data.message)
            commit('setSent', true)
          } else {
            console.log(data.message)
          }
        })
    }
  },
  modules: {
  }
})
