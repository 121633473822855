<template>
  <div class="controls">
    <div class="points-left">
      <b>{{ pointsLeft }}</b> punten over om te verdelen.
    </div>
    <div class="type-toggle">
      <normal-toggle
        name="bubbels"
        :invert="true"
        :initially-selected="listType == 'bubbles'"
        @change="state => state && setListType('bubbles')"
      />
      <normal-toggle
        name="lijst"
        :invert="true"
        :initially-selected="listType == 'list'"
        @change="state => state && setListType('list')"
      />
    </div>
  </div>
</template>

<script>
import NormalToggle from './NormalToggle.vue'

export default {
  components: { NormalToggle },
  computed: {
    pointsLeft() { return this.$store.getters.getPointsLeft },
    listType() { return this.$store.getters.getListType }
  },
  methods: {
    setListType(type) {
      this.$store.commit('setListType', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.controls {
  font-size: 0.7rem;
  transform: translateY(-0.5rem);
}
.controls::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
.controls>div {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: bottom;
}
.points-left {
  float: left;
  margin-top: 0.5rem;
}
.type-toggle {
  float:right;
}
</style>
