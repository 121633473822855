<template>
  <div class="explanation">
    <p><i>{{ typeName }}</i></p>
    <h2 class="explanation-title">
      {{ selectedItem.short }}
    </h2>
    <div class="item-points-holder">
      <div class="item-points">
        {{ selectedItem.points }} punten
      </div><div class="item-min" @click="min(selectedItem)">
        -
      </div>
      <div class="item-plus" @click="plus(selectedItem)">
        +
      </div>
    </div>
    <p class="explanation-text">
      {{ selectedItem.long }}
    </p>
    <div class="close-button" @click="close">
      <b-icon-x scale="1.5" />
    </div>
  </div>
</template>

<script>
import { BIconX } from 'bootstrap-vue'

export default {
  components: {
    BIconX
  },
  computed: {
    selectedItem() {
      return this.$store.getters.getSelectedItem
    },
    typeName() {
      return { threat: 'gevaar', opportunity: 'kans' }[this.selectedItem.type]
    }
  },
  methods: {
    close() {
      this.$store.commit('selectItem', null)
    },
    plus(item) {
      const currentBubbles = this.$store.getters.getCurrentBubbles
      if (currentBubbles) {
        currentBubbles.plus(item)
      }
    },
    min(item) {
      const currentBubbles = this.$store.getters.getCurrentBubbles
      if (currentBubbles) {
        currentBubbles.min(item)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/variables.scss';

.explanation {
  position: absolute;
  top: 2.5rem;
  z-index: 100;
  padding: 1rem;
  border-radius: 1rem;
  background: $yellow;
  box-shadow: -2px 8px 15px #fbdd73b0;
}

.explanation-title {
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

.close-button {
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.item-points-holder {
  text-align: left;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.item-points-holder>div {
  display: inline-block;
  text-align: center;
  padding: 2px;
}
.item-plus, .item-min {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  font-size: 1rem;
  background: $white;
  margin: 5px;
  box-shadow: -2px 8px 15px #d0d0d081;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
