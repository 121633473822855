<template>
  <div :class="['normal-toggle', selected ? 'selected' : '', invert ? 'invert' : '']" @click="toggle">
    <span>{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: { default: 'name', type: String },
    initiallySelected: { default: false, type: Boolean },
    invert: { default: false, type: Boolean }
  },
  data() {
    return {
      selected: this.initiallySelected
    }
  },
  watch: {
    initiallySelected(value) {
      this.selected = value
    }
  },
  methods: {
    toggle() {
      this.selected = !this.selected
      this.$emit('change', this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.normal-toggle {
    display: inline-block;
    // width: 5rem;
    height: 2rem;
    line-height: 1.8rem;
    padding: 0 1rem;
    margin: 0.25rem;
    text-align: center;
    font-size: 0.7rem;
    border-radius: 1rem;
    cursor: pointer;
    background-color: $white;
    transition: box-shadow 200ms, background-color 200ms;
}
span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}
.selected {
    background-color: $pink;
    box-shadow: -2px 8px 15px #f3d2c181;
    font-weight: 700;
}
.selected.invert {
    color: $pink;
    background-color: $color-blue-dark;
    box-shadow: -2px 8px 15px #0018573d;
}
</style>
