<template>
  <div :class="['normal-button', color, size]" @click="$emit('click')">
    <span>{{ name }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: { default: 'name', type: String },
    color: { default: 'pink', type: String },
    size: { default: 'm', type: String }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.normal-button {
  display: inline-block;
  // width: 5rem;
  margin: 0.25rem;
  text-align: center;
  cursor: pointer;
  background-color: $white;
  transition: box-shadow 200ms, background-color 200ms;
}
span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
.normal-button.m {
  height: 2rem;
  line-height: 0.8rem;
  padding: 0 1rem;
  font-size: 0.7rem;
  border-radius: 1rem;
}
.normal-button.l {
  height: 3rem;
  line-height: 2.7rem;
  padding: 0 2rem;
  font-weight: 700;
  font-size: 0.8rem;
  border-radius: 3rem;
}
.normal-button.pink {
  color: $color-blue-dark;
  background-color: $pink;
  box-shadow: -2px 8px 15px #f3d2c181;
}
.normal-button.blue {
  color: $pink;
  background-color: $color-blue-dark;
  box-shadow: -2px 8px 15px #0018573d;
}
.normal-button.yellow {
  color: $color-blue-dark;
  background-color: $yellow;
  box-shadow: -2px 8px 15px #fbdd73b0;
}
</style>
